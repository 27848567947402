import {EventBus} from "../events/EventBus";
import GoogleTracking from "./tracking/GoogleTracking";
import {WhitelabelEvent, WhitelabelEventWithPayload} from "../events/Events";
import GA4Tracking from "./tracking/GA4Tracking";
import http from "./http";
import {
    FILTERS_MODAL_OPENED_EVENT_TYPE,
    FILTERS_UPDATED_EVENT_TYPE,
    FiltersModalOpenedEventPayload,
    FiltersUpdatedEventPayload,
    MARKET_STATS_CLICKED_EVENT_TYPE,
    MARKET_STATS_DROPDOWN_CLICKED_EVENT_TYPE,
    MARKET_STATS_VIEWED_EVENT_TYPE,
    MarketStatsClickedEventPayload,
    MarketStatsViewedEventPayload,
    SEARCH_SAVED_EVENT_TYPE,
    SearchSavedEventPayload,
    SECTION_CLICKED_EVENT_TYPE,
    SectionClickedEventPayload
} from "../tracking/constants";
import FeatureFlags, {CORE_MARKET_STATS_WEB_TRACKING_EVENTS} from "../FeatureFlags";

let tracker: GoogleTracking | object;

let eventBus: EventBus;

const featureFlags: FeatureFlags = new FeatureFlags()

interface TrackAnalyticsEventPayload {
    name: string | undefined,
}

interface TrackAmplitudeEventPayload {
    type: string,
    serpUrl: string,
    section?: string,
    pageViewId?: string,
    filters?: any,
    sectionName?: string,
    value?: string,
}

class TrackAnalyticsEvent implements WhitelabelEventWithPayload<TrackAnalyticsEventPayload> {
    payload: TrackAnalyticsEventPayload;
    type: string;
    static readonly TYPE: string = 'tracking-track-event';

    constructor(payload: TrackAnalyticsEventPayload) {
        this.type = TrackAnalyticsEvent.TYPE;
        this.payload = payload;
    }
}

class TrackAmplitudeEvent implements WhitelabelEventWithPayload<TrackAmplitudeEventPayload> {
    payload: TrackAmplitudeEventPayload;
    type: string;
    static readonly TYPE: string = 'tracking-amplitude-event';

    constructor(payload: TrackAmplitudeEventPayload) {
        this.type = TrackAmplitudeEvent.TYPE;
        this.payload = payload;
    }
}

function getAmplitudeEventBody(payload: TrackAmplitudeEventPayload) {
    switch (payload.type) {
        case SEARCH_SAVED_EVENT_TYPE:
            const searchSavedEventData: SearchSavedEventPayload = {
                section: payload.section,
                eventType: payload.type,
                serpUrl: payload.serpUrl,
            };
            return searchSavedEventData;
        case FILTERS_MODAL_OPENED_EVENT_TYPE:
            const filtersModalOpenedEventData: FiltersModalOpenedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
            };
            return filtersModalOpenedEventData;
        case FILTERS_UPDATED_EVENT_TYPE:
            const filtersUpdatedEventData: FiltersUpdatedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                ...payload.filters,
            };
            return filtersUpdatedEventData;
        case SECTION_CLICKED_EVENT_TYPE:
            const sectionClickedEventData: SectionClickedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                sectionName: payload.sectionName,
                value: payload.value,
                sectionId: payload.section,
                pageViewId: payload.pageViewId!,
            };
            return sectionClickedEventData;
        case MARKET_STATS_CLICKED_EVENT_TYPE:
            const marketStatsClickedEventData: MarketStatsClickedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                sectionId: payload.section!,
                sectionName: payload.sectionName!,
                pageViewId: payload.pageViewId!,
                operationType: payload.filters["operationType"],
                propertyType: payload.filters["propertyType"],
                geoId: payload.filters["geoId"],
                rooms: payload.filters["rooms"],
                alternativeGeoId: payload.filters["alternativeGeoId"],
                amenity: payload.filters["amenity"],
            };
            return marketStatsClickedEventData;
            case MARKET_STATS_VIEWED_EVENT_TYPE:
            const marketStatsViewedEventData: MarketStatsViewedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                sectionId: payload.section!,
                sectionName: payload.sectionName!,
                pageViewId: payload.pageViewId!,
                operationType: payload.filters["operationType"],
                propertyType: payload.filters["propertyType"],
                geoId: payload.filters["geoId"],
            };
            return marketStatsViewedEventData;
        /*case MARKET_STATS_DROPDOWN_CLICKED_EVENT_TYPE:
            const marketStatsDropdownClickedEventData: MarketStatsDropdownClickedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                sectionId: payload.section!,
                sectionName: payload.sectionName!,
                pageViewId: payload.pageViewId!,
                operationType: 'TODO',
                propertyType: 'TODO',
                geoId: 'TODO',
                previousGeoId: 'TODO',
                selectedGeoId: 'TODO',
                previousPriceEvolutionType: 'TODO',
                selectedPriceEvolutionType: 'TODO',
            };
            return marketStatsDropdownClickedEventData;
        */
        default:
            return {};
    }
}

export function trackAmplitudeEventHandler(event: WhitelabelEvent) {
    const { payload} = event as TrackAmplitudeEvent;

    if (payload.type === "SearchSaved" && payload.section == null) {
        return;
    }
    if ([
        MARKET_STATS_VIEWED_EVENT_TYPE,
        MARKET_STATS_CLICKED_EVENT_TYPE,
        MARKET_STATS_DROPDOWN_CLICKED_EVENT_TYPE
    ].includes(payload.type) && !featureFlags.isActiveFeature(CORE_MARKET_STATS_WEB_TRACKING_EVENTS)) {
        return;
    }

    eventBus.emit(new http.PostEvent({
        url: '/api/tracking/track',
        body: getAmplitudeEventBody(payload),
    }));
}

export function trackAnalyticsEventHandler(event: WhitelabelEvent) {
    const { payload: { name }} = event as TrackAnalyticsEvent;
    if (name == null) {
        return;
    }

    (tracker as GoogleTracking).trackEvent(name);
}

function init(tr: GoogleTracking | object = GA4Tracking, ev: any = EventBus.getInstance()) {
    tracker = tr;
    eventBus = ev;
    eventBus.subscribe(TrackAnalyticsEvent.TYPE, trackAnalyticsEventHandler);
    eventBus.subscribe(TrackAmplitudeEvent.TYPE, trackAmplitudeEventHandler);
}

export default {
    TrackAnalyticsEvent,
    TrackAmplitudeEvent,
    init,
}
